@use "src/styles/settings" as *;
.SelectedRow {
  display: flex;
  align-items: center;
  padding: .5rem .75rem;
  border: .0625rem solid colors(gray-200);
  border-radius: .5rem;
}
.NameBlock {
  display: flex;
  flex-direction: column;
  > span + span {
    margin-top: .25rem;
  }
}
.OwnerKey {
  font-size: .75rem;
  color: colors(gray-700);
}
.OwnerName {
  font-weight: 700;
}
.Buttons {
  margin-left: auto;
}
.DeleteLink {
  color: colors(negative);
  &:hover {
    color: colors(negative);
  }
}

@use "src/styles/settings" as *;
.Container {
  margin: .5rem;
}
.OutlineArea {
  margin-bottom: 1rem;
}
.Title {
  margin-top: 2rem;
  margin-bottom: .6rem;
  font-size: 1.2rem;
  font-weight: 700;
}
.BasicContainer {
  .BasicRow:last-child {
    border-bottom: .063rem solid colors(gray-200);
  }
}
.BasicRow {
  display: flex;
  border-top: .063rem solid colors(gray-200);
}
.Label {
  display: flex;
  align-items: center;
  width: 16rem;
  padding: 1rem;
  background-color: colors(gray-100);
}
.LabelText {
  font-weight: 700;
  color: colors(gray-700);
}
.Content {
  flex: 1;
  padding: 1rem;
}
.Discription {
  margin-bottom: .5rem;
  font-size: .8rem;
}
.AlertInformationText {
  font-size: .9rem;
}
.TableContainer {
  width: 100%;
  margin-top: 1rem;
}
.TableHeader {
  margin-top: 1rem .5rem;
  background-color: colors(gray-100);
  border: .1rem solid colors(gray-200);
}
.TableHeaderRow {
  width: 35%;
}
.TableHeaderCell {
  padding: .9rem .3rem;
  border-right: .1rem solid colors(gray-200);
  border-left: .1rem solid colors(gray-200);
}
.TableRow {
  border: .1rem solid colors(gray-200);
}
.TableData {
  padding: 1rem .5rem;
  vertical-align: middle;
  border-right: .1rem solid colors(gray-200);
  border-left: .1rem solid colors(gray-200);
}

@use "src/styles/settings" as *;
.Container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem 2rem;
  width: 100%;
  & > * {
    flex-basis: calc(50% - 1rem);
  }
}

@use "src/styles/settings" as *;
.Form {
  padding: 0 0 1rem;
}
.InputLabel {
  margin: 1rem 0 .5rem;
  font-size: 1rem;
  color: colors(gray-700);
}
.PeriodWrapper {
  display: flex;
  gap: .4rem;
  align-items: center;
}
.SearchButton {
  display: block;
  margin-top: 1rem;
  margin-left: auto;
}
.List {
  margin-top: 1rem;
}
.SeisanKanrikunContract {
  display: flex;
  align-items: center;
  width: 100%;
  padding: .5rem 1rem;
  color: colors(gray-700);
  border-bottom: .0625rem solid colors(gray-200);
}
.CollaborateButton {
  margin-left: auto;
  color: colors(link-text);
}
.FetchResultText {
  font-size: 1rem;
  color: colors(gray-700);
}
.SeisanKanrikunList {
  padding: .25rem;
}

@use "src/styles/settings" as *;
.AdminArea {
  align-items: center;
  padding: .6rem;
  background-color: colors(gray-200);
  .AdminAreaText {
    margin-left: 1rem;
    font-weight: bold;
    color: colors(negative);
  }
}

@use "src/styles/settings" as *;
.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 2%;
}
.Title {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
}
.Button {
  margin-top: 20px;
}

@use "src/styles/settings" as *;
.Container {
  position: relative;
  display: flex;
  padding: 1.5rem 2% 20rem;
  @include mq() {
    flex-direction: column;
  }
}
.TabArea {
  width: 20rem;
  margin-right: 1.5rem;
  @include mq() {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
.Contents {
  flex: 1;
  max-width: 64rem;
}

@use "src/styles/settings" as *;
.SelectedList {
  > div + div {
    margin-top: .25rem;
  }
}
.SelectButton {
  width: 100%;
  padding: .75rem 1rem;
  margin-top: .25rem;
  color: colors(link-text);
  border-radius: .25rem;
  &:hover {
    color: colors(link-hover);
  }
}

@use "src/styles/settings" as *;
.Additional {
  margin-left: .25rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.Empty {
  color: colors(gray-400);
}
.Addresses {
  .Additional {
    margin-bottom: .25rem;
  }
  > .AddressBlock + .AddressBlock {
    padding-top: .5rem;
    margin-top: .5rem;
    border-top: .0625rem dashed colors(gray-200);
  }
}
.NameWrapper {
  display: flex;
  flex-direction: column;
  gap: .25rem;
}

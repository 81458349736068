@use "src/styles/settings" as *;
.BreadcrumbsCurrent {
  color: colors(gray-700);
}
.BreadcrumbsLink {
  color: colors(gray-900);
  &:hover {
    color: colors(gray-900);
    text-decoration: underline;
  }
}

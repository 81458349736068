@use "src/styles/settings" as *;
.OwnerLink {
  display: flex;
  align-items: center;
  padding: .5rem 1rem;
  color: colors(black);
  text-decoration: none;
  border-bottom: .0625rem solid colors(gray-200);
  transition: background-color 150ms ease-out 0s;
  &:hover, &:focus {
    color: colors(black);
    text-decoration: none;
    background-color: colors(gray-100);
  }
  &:active {
    background-color: colors(gray-100);
  }
}
.OwnerKey {
  font-size: .75rem;
  color: colors(gray-700);
}
.ArrowLink {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  color: colors(gray-600);
}

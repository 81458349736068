@use "src/styles/settings" as *;
.InputLabel {
  margin: 1rem 0 .5rem;
  font-size: 1rem;
  color: colors(gray-700);
}
.FileInput {
  padding: 0;
  border: none;
  outline: none;
}
.Button {
  display: block;
  margin-top: 1rem;
  margin-left: auto;
}

$breakpoints: (
  "xxl": "screen and (max-width: 1440px)",
  "xl": "screen and (max-width: 1140px)",
  "lg": "screen and (max-width: 960px)",
  "md": "screen and (max-width: 768px)"
) !default;
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

$colors: (
  black: #212121,
  white: #fff,
  semantic: #0080ff,
  semantic-link100: #e8f3ff,
  semantic-linkhover: #4da6ff,
  semantic-positive: #58a942,
  semantic-positive-hover: #44952e,
  semantic-positive-100: #eff7ed,
  semantic-warning: #f0ad4e,
  semantic-warning-100: #fef7ee,
  gray-50: #f9f8fa,
  gray-100: #f4f3f6,
  gray-200: #e4e4e5,
  gray-300: #c9c8cb,
  gray-400: #aeadb1,
  gray-500: #949298,
  gray-600: #79767e,
  gray-700: #5e5b64,
  gray-800: #433f4a,
  gray-900: #282430,
  link-text: #187fc3,
  link-hover: #27a2f2,
  link-bg: rgb(39 162 242 / 10%),
  border: #dfdfdf,
  primary: #187fc3,
  negative: #f44336,
  negative-100: #feedeb,
);
@function colors($key) {
  @return map-get($colors, $key);
}

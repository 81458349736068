@use "src/styles/settings" as *;
.SpinnerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 32px;
  background-color: rgb(255 255 255 / 90%);
}

@use "src/styles/settings" as *;
.Additional {
  margin-left: .75rem;
}
.Empty {
  color: colors(gray-400);
}
.Addresses {
  .Additional {
    margin-bottom: .25rem;
  }
  > .AddressBlock + .AddressBlock {
    padding-top: .5rem;
    margin-top: .5rem;
    border-top: .0625rem dashed colors(gray-200);
  }
}
.NameWrapper {
  display: flex;
  flex-direction: column;
  gap: .25rem;
}
.BasicBox {
  display: flex;
  justify-content: center;
}
.LeftWrapper {
  flex: 1;
  margin-right: .5rem;
}
.RightWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: .5rem;

  div {
    margin-bottom: .5rem;
  }
}
.BottomWrapper {
  margin-top: 1rem;
}
.Card {
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  background-color: colors(white);
  border: .1rem solid colors(gray-300);
  border-radius: .25rem;
}
.CardTitleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

@use "src/styles/settings" as *;
.FormContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 1.5rem;
  background-color: colors(white);
}
.EditorSection {
  flex: 6;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.ScrollspySection {
  flex: 1;
  position: sticky;
  top: 1rem;
  align-self: flex-start;
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
}
.FormBlock {
  display: flex;
  margin-bottom: 1.5rem;
}
.Icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .75rem;
}
.FormBlockLabel {
  width: 10rem;
  min-width: 10rem;
  padding: .5rem;
  font-size: 1rem;
  font-weight: 700;
  border-right: .0625rem solid colors(gray-200);
}
.FormContents {
  flex: 1;
  padding: .5rem 1.5rem;
}
.FormLabel {
  margin-bottom: .25rem;
}
.FormInputBlock {
  max-width: 25rem;
  margin-bottom: 1.25rem;
}
.NameFormBlock {
  display: flex;
  margin-bottom: .5rem;
  > div + div {
    margin-left: .5rem;
  }
}

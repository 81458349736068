@use "src/styles/settings" as *;
.StatusLabel {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  padding: 0 .5rem;
  font: bold .75rem/1 sans-serif;
  border-style: solid;
  border-radius: .125rem;
}
.VariantNormal {
  color: colors(gray-700);
  background-color: colors(gray-200);
  border: none;
}
.VariantSuccess {
  color: colors(semantic-positive);
  background-color: colors(semantic-positive-100);
  border: .0625rem solid colors(semantic-positive);
}
.VariantDanger {
  color: colors(negative);
  background-color: colors(negative-100);
  border: .0625rem solid colors(negative);
}

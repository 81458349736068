@use "src/styles/settings" as *;
.Tabs {
  position: sticky;
  top: 7rem;
  display: flex;
  flex-direction: column;
}
.Tab {
  border-bottom: .0625rem solid colors(gray-200);
  &.Active {
    font-weight: 700;
    background-color: colors(gray-200);
    border-radius: .25rem;
  }
  &.Disabled {
    opacity: .5;
  }
  &:hover {
    background-color: colors(gray-100);
    &.Active {
      background-color: colors(gray-200);
    }
  }
}
.TabButton {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
}
.RightArrow {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  color: colors(gray-400);
}
.ScrollspySection {
  flex: 1;
  position: sticky;
  top: 1rem;
  align-self: flex-start;
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
}

@use "src/styles/settings" as *;
.FooterArea {
  position: sticky;
  bottom: 0;
  display: flex;
  padding: 1rem 2%;
  background-color: colors(white);
  box-shadow: 0 0 10px rgb(40 36 48 / 20%);
}
.SaveButton {
  width: 10rem;
  margin-left: auto;
}
.CancelButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

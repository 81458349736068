@use "src/styles/settings" as *;
.TitleHeader {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 1rem 2%;
  background-color: colors(white);
  box-shadow: 0 .125rem .5rem rgb(0 0 0 / 10%);
}
.BreadcrumbsWrapper {
  margin-bottom: 1rem;
}
.TitleContents {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.Title {
  font-size: 1.5rem;
  font-weight: 700;
}
.Owners {
  display: flex;
  align-items: center;
}
.OwnerIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .25rem;
  color: colors(gray-900);
}
.OwnerName {
  color: colors(gray-900);
  text-decoration: underline;
  &:hover {
    color: colors(gray-800);
    text-decoration: underline;
  }
}
.ApprovalArea {
  display: flex;
  align-items: center;
  padding: .6rem;
  margin-top: .8rem;
  border: .0625rem solid colors(negative);
  .ApprovalAreaText {
    color: colors(negative);
  }
  .ApprovalAreaButtonWrapper {
    margin-left: auto;
  }
}

@use "src/styles/settings" as *;
.Selected {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem .75rem;
  border: .0625rem solid colors(gray-200);
  border-radius: .5rem;
}
.SelectedName {
  display: flex;
  align-items: center;
}
.DeleteLink {
  margin-left: auto;
  color: colors(negative);
  &:hover {
    color: colors(negative);
  }
}

@use "src/styles/settings" as *;
.SelectButton {
  width: 100%;
  padding: .75rem 1rem;
  margin-top: .25rem;
  color: colors(link-text);
  border-radius: .25rem;
  &:hover {
    color: colors(link-hover);
  }
}
.CheckItem {
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  padding: .5rem 1rem;
  color: colors(black);
  border-bottom: .0625rem solid colors(gray-200);
}
.Selected {
  display: flex;
  align-items: center;
  width: 50%;
  padding: 1rem;
  border: .0625rem solid colors(gray-200);
  border-radius: .5rem;
}
.DeleteLink {
  margin-left: auto;
  color: colors(negative);
  &:hover {
    color: colors(negative);
  }
}
.Title {
  margin-bottom: .5rem;
}

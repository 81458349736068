@use "src/styles/settings" as *;
.Description {
  margin-top: .5rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.ErrorMessage {
  width: 100%;
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(negative);
}
.Title {
  margin-bottom: .5rem;
}
.InputRadioGroup {
  display: flex;
  gap: 1rem;
}
.NameInputGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.DefaultRow {
  display: flex;
  gap: .75rem;
  max-width: 40rem;
}
.NameInnerBlock {
  flex: 1;
}
.NameLabel {
  display: block;
  margin-bottom: .25rem;
  font-weight: 400;
}
.KanaLabel {
  display: block;
  margin-top: .5rem;
  margin-bottom: .25rem;
  font-weight: 400;
}
.RequiredLabel {
  margin-left: auto;
}
.BirthdayInput{
  width: 40rem;
}
.AddressLabel {
  display: block;
  margin-bottom: .25rem;
}
.ShippingAddressWrapper {
  display: flex;
  align-items: center;
}
.ZipcodeInputWrapper {
  max-width: 10rem;
  margin-bottom: .5rem;
}
.AddressFirstRow {
  display: flex;
  gap: 1rem;
  max-width: 40rem;
  margin-bottom: .5rem;
}
.AddressSecondRow {
  display: flex;
  gap: 1rem;
  max-width: 40rem;
}
.AddressThirdRow {
  display: flex;
  gap: 1rem;
  max-width: 40rem;
}
.AddressLeftInput {
  flex: 1;
}
.AddressRightInput {
  flex: 2;
}
// Bank Account
.DepositRow {
  display: flex;
  gap: .25rem;
}
.BankAccountInnerBlock{
  gap: 1rem;
  max-width: 40rem;
  margin-bottom: .5rem;
}
.BankAccountLastInnerBlock{
  gap: 1rem;
  max-width: 40rem;
}
.DepositLeftInput {
  flex: 1;
}
.DepositRightInput {
  flex: 2;
}
// Other Css
.SelectButton {
  width: 100%;
  padding: .75rem 1rem;
  margin-top: .25rem;
  color: colors(link-text);
  border-radius: .25rem;
  &:hover {
    color: colors(link-hover);
  }
}
.CheckItem {
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  padding: .5rem 1rem;
  color: colors(black);
  border-bottom: .0625rem solid colors(gray-200);
}
.Selected {
  display: flex;
  align-items: center;
  width: 50%;
  padding: 1rem;
  border: .0625rem solid colors(gray-200);
  border-radius: .5rem;
}
.DeleteLink {
  margin-left: auto;
  color: colors(negative);
  &:hover {
    color: colors(negative);
  }
}
.Title {
  margin-bottom: .5rem;
}

@use "src/styles/settings" as *;
.FormRow {
  display: flex;
  border-top: .1rem solid colors(gray-200);
}
.FormLabel {
  display: flex;
  align-items: center;
  width: 16rem;
  padding: 1rem;
  background-color: colors(gray-100);
}
.RequiredLabel {
  margin-left: auto;
}
.FormLabelText {
  font-weight: 700;
  color: colors(gray-700);
}
.FormContent {
  flex: 1;
  padding: 1rem;
}
.ErrorMessage {
  width: 100%;
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(negative);
}

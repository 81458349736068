.TableData {
  display: flex;
  align-items: center;
  padding: 0 .5rem;
  white-space: pre-wrap;
}
.VerticalTableData {
  align-items: center;
  padding: 1rem .5rem;
  white-space: pre-wrap;
}

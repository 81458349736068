@use "src/styles/settings" as *;
.accordion {
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  width: 20rem;
  margin-top: 2rem;
  margin-left: auto;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
}

.accordionContent {
  padding: 16px;
  border-top: 1px solid #ccc;
}

.accordionContent span {
  margin-right: .5rem;
}

@use "src/styles/settings" as *;
.MiddleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.CreateButton {
  margin-left: auto;
  padding: 4px 8px 4px 8px;
}
.CreateIcon {
  padding-bottom: 1px;
  padding-left: 4px;
  color: #fff;
}

@use "src/styles/settings" as *;
.SearchArea {
  display: flex;
  gap: .8rem;
  align-items: center;
}
.SearchInput {
  width: 13rem;
  max-width: 20rem;
}
.SearchIcon {
  width: 100%;
}
.ButtonWrapper {
  display: flex;
  align-items: center;
}

@use "src/styles/settings" as *;
.ToggleWrapper {
  display: flex;
  flex-direction: column;
  gap: .4rem;
  margin-bottom: .5rem;
}
.ButtonWrapper {
  display: flex;
  justify-content: end;
  margin-top: 1.2rem;
}
.OwnerList {
  margin-top: 1rem;
}

@use "src/styles/settings" as *;
.Container {
  padding: 1rem 2% 0;
}
.Breadcrumbs {
  margin-bottom: 0.5rem;
}
.Title {
  margin: 0.5rem 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.BodyContainer {
  padding: 0 2%;
  margin-bottom: 20rem;
}

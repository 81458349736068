@use "src/styles/settings" as *;
.TitleHeader {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 1rem 2%;
  background-color: colors(white);
  box-shadow: 0 .125rem .5rem rgb(0 0 0 / 10%);
}
.BreadcrumbsWrapper {
  margin-bottom: 1rem;
}
.Title {
  font-size: 1.5rem;
  font-weight: 700;
}

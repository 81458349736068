@use "src/styles/settings" as *;
.CollaboratedSeisanKanrikunOwner {
  margin-bottom: 2rem;
  font-size: .8rem;
  font-weight: bold;
  color: colors(semantic-positive);
}
.Form {
  margin-bottom: 1rem;
}
.InputLabel {
  margin: 1rem 0 .5rem;
  font-size: 1rem;
  color: colors(gray-700);
}
.SearchButton {
  display: block;
  margin-top: 1rem;
  margin-left: auto;
}
.List {
  margin-top: 1rem;
}
.SeisanKanrikunOwner {
  display: flex;
  align-items: center;
  width: 100%;
  padding: .5rem 1rem;
  color: colors(gray-700);
  border-bottom: .0625rem solid colors(gray-200);
}
.CollaborateButton {
  margin-left: auto;
  color: colors(link-text);
}
.FetchResultText {
  font-size: 1rem;
  color: colors(gray-700);
}
.Link {
  display: block;
  margin-top: 2rem;
  text-align: end;
}

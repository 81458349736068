@use "src/styles/settings" as *;
.TitleHeader {
  margin-bottom: .5rem;
}
.TitleBox {
  display: flex;
  justify-content: space-between;
}
.BreadcrumbsWrapper {
  margin-bottom: .5rem;
}
.Title {
  font-size: 1.5rem;
  font-weight: 700;
}

@use "src/styles/settings" as *;
.Breadcrumbs {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: .75rem;
  list-style: none;
  > li + li {
    &::before {
      margin: 0 .5rem;
      color: colors(gray-700);
      content: "/";
    }
  }
}

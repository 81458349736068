@use "src/styles/settings" as *;
.SearchArea {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  font-size: 1rem;
}
.FormArea {
  display: flex;
  flex-direction: column;
  gap: .4rem;
}
.DateInput {
  width: 10rem;
}
.PeriodWrapper {
  display: flex;
  gap: .4rem;
  align-items: center;
}
.ToggleWrapper {
  display: flex;
  flex-direction: column;
  gap: .4rem;
}
.Button {
  width: 100%;
}

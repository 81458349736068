@use "src/styles/settings" as *;
.Row {
  display: flex;
  padding: 1rem;
  border-bottom: .0625rem solid colors(gray-200);
}
.Caption {
  width: 15rem;
  font-weight: 700;
  color: colors(gray-700);
  @include mq() {
    width: 50%;
  }
}
.Content {
  flex: 1;
  white-space: pre-wrap;
}

@use "src/styles/settings" as *;
.Card {
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  background-color: colors(white);
  border: .1rem solid colors(gray-300);
  border-radius: .25rem;
}
.CardTitleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.CardTitleIcon {
  & > * {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    color: colors(gray-400);
  }
}
.CardTitle {
  font-size: 1.25rem;
  font-weight: 700;
}
.CardButtonWrapper {
  display: flex;
  gap: 12px;
  margin-left: auto;
}

@use "src/styles/settings" as *;
.CreateButton {
  display: block;
  margin-left: auto;
}
.SearchButton {
  display: flex;
  align-items: center;
  margin-bottom: .4rem;
  font-size: 1rem;
  color: colors(primary);
}

@use "src/styles/settings" as *;
.FooterArea {
  position: sticky;
  bottom: 0;
  z-index: 100;
  display: flex;
  padding: 1rem 2%;
  background-color: colors(white);
  box-shadow: 0 0 10px rgb(40 36 48 / 20%);
}
.Buttons {
  display: flex;
  margin-left: auto;
}
.SaveButton {
  width: 12rem;
  margin-left: 1rem;
}

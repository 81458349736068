@use "src/styles/settings" as *;
.TableHeader {
  padding: 1rem .5rem;
  font-weight: normal;
  border-right: 1px solid colors(gray-200);
}

.TableHeader div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 .25rem;
}

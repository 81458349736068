@use "src/styles/settings" as *;
.DatePickerWrapper {
  position: relative;
  display: inline-block;
}
.Icon {
  position: absolute;
  top: .875rem;
  right: .875rem;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  color: colors(gray-500);
}

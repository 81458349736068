@use "src/styles/settings" as *;
.Container {
  position: relative;
  display: flex;
  padding: 1.5rem 2% 20rem;
  @include mq() {
    flex-direction: column;
  }
}
.TabArea {
  width: 20rem;
  margin-right: 1.5rem;
  @include mq() {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
.Contents {
  flex: 1;
  max-width: 64rem;
}
.NewContainer {
  position: relative;
  display: flex;
  padding: 1rem 2% 20rem;
  @include mq() {
    flex-direction: column;
  }
}
.NewContents {
  flex: 1;
  max-width: 80%;
  @include mq() {
    max-width: 100%;
  }
}
.NewTabArea {
  width: 20%;
  margin-left: 1.5rem;
  @include mq() {
    width: 100%;
    margin-left: 0;
    margin-bottom: 1.5rem;
  }
}

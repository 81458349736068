@use "src/styles/settings" as *;
.Container {
  display: flex;
  align-items: flex-end;
  margin-bottom: .425rem;
  margin-right: .5rem;
}
.TotalCount {
  margin-right: .5rem;
  font-size: 1.25rem;
}

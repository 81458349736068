@use "src/styles/settings" as *;
.Block {
  display: flex;
}
.BlockCaptionWrapper {
  width: 12.5rem;
  border-right: .1rem solid colors(gray-300);
}
.BlockCaption {
  display: flex;
  align-items: center;
  padding-top: .5rem;
}
.BlockIconWrapper {
  padding: .5rem;
  background-color: colors(gray-200);
  border-radius: 99rem;
  & > * {
    width: 1.25rem;
    height: 1.25rem;
  }
}
.BlockCaptionLabel {
  margin-left: .5rem;
  font-size: 1rem;
  font-weight: 700;
}
.BlockForms {
  flex: 1;
  padding: 0 1rem;
  & > *:last-child {
    border-bottom: .1rem solid colors(gray-200);
  }
}

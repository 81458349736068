@use "src/styles/settings" as *;
.MenuItem {
  display: flex;
  align-items: center;
  padding: 1.2rem;
  cursor: pointer;
  background-color: colors(white);
  border-radius: .8rem;
  box-shadow: 0 .1rem .2rem colors(gray-400);
}
.TextWrapper {
  margin-right: auto;
  .Title {
    margin-bottom: .4rem;
    font-size: 1.2rem;
    font-weight: 700;
  }
  .Description {
    font-size: 1rem;
  }
}
.Icon {
  font-size: 1.6rem;
}

@use "src/styles/settings" as *;
.Description {
  margin-top: .5rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.ErrorMessage {
  width: 100%;
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(negative);
}
.Title {
  margin-bottom: .5rem;
}
.InputRadioGroup {
  display: flex;
  gap: 1rem;
}
.NameInputGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.NameRow {
  display: flex;
  gap: .75rem;
  max-width: 30rem;
}
.NameInnerBlock {
  flex: 1;
}
.NameLabel {
  display: block;
  margin-bottom: .25rem;
  font-weight: 400;
}
.KanaLabel {
  display: block;
  margin-top: .5rem;
  margin-bottom: .25rem;
  font-weight: 400;
}
.RequiredLabel {
  margin-left: auto;
}
.AddressLabel {
  display: block;
  margin-bottom: .25rem;
}
.ShippingAddressWrapper {
  display: flex;
  align-items: center;
}
.ZipcodeInputWrapper {
  max-width: 10rem;
  margin-bottom: .5rem;
}
.AddressFirstRow {
  display: flex;
  gap: 1rem;
  max-width: 40rem;
  margin-bottom: .5rem;
}
.AddressSecondRow {
  display: flex;
  gap: 1rem;
  max-width: 40rem;
}
.AddressThirdRow {
  display: flex;
  gap: 1rem;
  max-width: 40rem;
}
.AddressLeftInput {
  flex: 1;
}
.AddressRightInput {
  flex: 2;
}
.DepositRow {
  display: flex;
  gap: .25rem;
}
.BankAccountInnerBlock{
  gap: 1rem;
  max-width: 40rem;
  margin-bottom: .5rem;
}
.BankAccountLastInnerBlock{
  gap: 1rem;
  max-width: 40rem;
}
.BankAccountBankBranchBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 3rem;
  background-color: #fefdff;
  border: 2px solid #c9c8cb;
  border-radius: 4px;
  color: #282430;    
}
.BankAccountBankBranchBoxInner {
  flex: 1;
  padding: 0 10px;
  font-size: 14px;
  line-height: 1.5;
  color: #282430;
  background-color: transparent;
  height: 100%;
}
.DepositLeftInput {
  flex: 1;
}
.DepositRightInput {
  flex: 2;
}

@use "src/styles/settings" as *;
.AboveFormLabel {
  margin-bottom: .5rem;
}
.ColumnLayout {
  flex-direction: column;
  margin-right: 3rem;
}
.FormLabelText {
  font-weight: 700;
  color: colors(gray-700);
}
.FlexFormContent {
  display: flex;
}
.Description {
  margin-top: .5rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.ErrorMessage {
  width: 100%;
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(negative);
}
.InputRadioGroup {
  display: flex;
  gap: 1rem;
}
.InputWithUnitWrapper {
  display: flex;
  gap: .5rem;
  align-items: center;
  max-width: 20rem;
}
.NumberInput {
  flex: 1;
  width: 18rem;
}
.Unit {
  width: 5rem;
}

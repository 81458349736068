@use "src/styles/settings" as *;
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 40%);
  animation: overlay .15s ease-in;
}
@keyframes overlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Menu {
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 300;
  height: 100vh;
  padding: 2rem 1rem;
  overflow: scroll;
  background-color: colors(white);
  animation: menu-wrapper .5s forwards;
}
@keyframes menu-wrapper {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}

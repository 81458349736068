@use "src/styles/settings" as *;
.List {
  margin-top: 1rem;
}
.Link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: .5rem 1rem;
  color: colors(black);
  text-decoration: none;
  border-bottom: .0625rem solid colors(gray-200);
  transition: background-color 150ms ease-out 0s;
  &:hover, &:focus {
    color: colors(black);
    text-decoration: none;
    background-color: colors(gray-100);
  }
  &:active {
    background-color: colors(gray-100);
  }
}
.Key {
  font-size: .75rem;
  color: colors(gray-700);
}
.ArrowLink {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  color: colors(gray-600);
}
.FetchResultText {
  font-size: 1rem;
  color: colors(gray-700);
}
.ButtonWrapper {
  display: flex;
  justify-content: end;
  margin-top: 1.2rem;
}
.BackSelectBuildingLink {
  display: block;
  margin-bottom: 1.2rem;
  color: colors(link-text);
  cursor: pointer;
  &:hover {
    color: colors(link-hover);
  }
}

@use "src/styles/settings" as *;
.Label {
  display: flex;
  align-items: center;
  padding: .4rem;
  margin-top: 1rem;
  background-color: colors(white);
  border: .1rem solid colors(gray-200);
  border-radius: .4rem;
  .Icon {
    width: 2.4rem;
  }
  .TextsWrapper {
    margin-left: .8rem;
    .Text {
      font-size: .8rem;
      font-weight: bold;
    }
    .SubText {
      font-size: .7rem;
    }
  }
  .ExternalLink {
    margin-bottom: auto;
    margin-left: .4rem;
  }
  .ExternalLinkIcon {
    width: 1rem;
    height: 1rem;
  }
  .Button {
    padding: 0 1rem;
    margin-left: auto;
    color: colors(link-text);
    &:hover {
      color: colors(link-hover);
    }
  }
}
